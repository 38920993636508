<template>
  <div class="my-2">
    <table class="w-full table bg-white">
      <thead class="bg-gradient">
        <tr class="">
          <th class="table-data">No</th>
          <th class="table-data" v-for="column of columns">
            {{ column }}
          </th>
          <th v-if="hasActions">
            Actions
          </th>
        </tr>
      </thead>
      <tbody class="">
        <tr v-for="(data, index) of tableData">
          <td>{{ index+1 }} </td>
          <td class="table-data" v-for="key of Object.keys(columns)">
            {{ data[key] }}
          </td>
          <td v-if="hasActions">
            <slot name="actions" v-bind:data="data"></slot>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "SimpleDataTable",
  props: {
    columns: [],
    tableData: [],
    hasActions: Boolean
  }
}
</script>

<style scoped>

</style>