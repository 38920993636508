<template>
  <div>
    <app-header-no-back title="Dashboard"></app-header-no-back>
    <div v-if="organizationDashboardData.organizationLocations">
      <div class="col-md-4 slide-left bg-white my-8 p-4 rounded-md border-1 border-primary2fade/5">
        <div class="flex items-center">
          <div class="h-12 w-12 bg-primary2variant-50 rounded-full flex justify-center items-center">
            <icon name="users" width="h-8" height="w-8" color="#0B5073"></icon>
          </div>
          <div class="mx-4">
            <h2 class="font-bold text-xl text-primary2">
              Total Institutions
            </h2>
            <span class="font-bold text-3xl">
            {{ organizationDashboardData.totalOrganizations }}
          </span>
          </div>
        </div>

      </div>

      <chart-component class="slide-left" title="Institution By Location"
                       :categories="organizationDashboardData.organizationLocations"
                       :series="series" y-axis="Institituions" chart-type="v-bar"></chart-component>
    </div>
  </div>
</template>

<script>
import AppHeaderNoBack from "@/components/shared/AppHeaderNoBack.vue";
import ChartComponent from "@/components/shared/ChartComponent.vue";
import Icon from "@/components/shared/Icon.vue";

export default {
  name: "AdminDashboard",
  components: {Icon, ChartComponent, AppHeaderNoBack},
  data() {
    return {
      organisations: [],
      organizationDashboardData: {
        totalOrganizations: 0
      },
      series: [{
        name: 'Locations',
        data: [4]
      }]
    }
  },
  methods: {
    organizationsData() {
      let organizationLocations = []
      let organizationCounts = []
      for (const organization of this.organisations) {
        this.organizationDashboardData.totalOrganizations += 1
        let officeLocation = organizationLocations.find((organizationLocation) => organizationLocation === organization.officeLocation)
        if (!officeLocation) {
          organizationLocations.push(organization.officeLocation)
          organizationCounts.push(1)
        }
        else {
          let organizationIndex = organizationLocations.findIndex((organizationLocation) => organizationLocation === organization.officeLocation)
          organizationCounts[organizationIndex] += 1
        }
      }
      this.organizationDashboardData = {
        ...this.organizationDashboardData,
        organizationLocations,
        organizationCounts
      }
      this.series[0].data = organizationCounts
    }
  },
  mounted() {
    this.$store.dispatch('clearOrganizations')
    this.$store.dispatch('setLoadingStatus', true)
    this.$store.dispatch('fetchAllOrganizations').then(
        () => {
          this.organisations = this.$store.getters.getAllOrganizations
          this.$store.dispatch('setLoadingStatus', false)
          this.organizationsData()
        }
    )
  }
}
</script>

<style scoped>

</style>