<template>
  <div >
    <app-header title="Documents"> </app-header>
    <document-add ></document-add>
    <div class="my-2">
      <simple-data-table :columns="columns" :table-data="documents"
      has-actions="true">
        <template #actions="{ data }">
          <action-button  @click="openDoc(data)" name="View" styles="bg-gradient">
          </action-button>
        </template>
      </simple-data-table>
    </div>

  </div>
</template>

<script>
import AppHeader from "@/components/shared/AppHeader";

import DocumentAdd from "@/views/shared/Documents/DocumentAdd";
import SimpleDataTable from "@/components/shared/SimpleDataTable.vue";
import ActionButton from "@/components/shared/ActionButton.vue";

export default {
  name: "DocumentList",
  components: {ActionButton, DocumentAdd, AppHeader, SimpleDataTable},
  data() {
    return {
      columns: {
        documentName: 'Document Name',
        documentDescription: 'Description',
        time: 'Created Date'
      },
      documents: [],
      pdfSrc: '/Real.pdf'
    }
  },
  methods: {
    openDoc(document){

      this.$router.push(`/${this.$route.meta.userAccessed}/document-details/${document.documentCid}`)

    },
    async retrieveFiles() {
      this.$store.dispatch('clearDocuments')
      this.$store.dispatch('setLoadingStatus', true)
      this.$store.dispatch('fetchAllDocuments').then(
          () => {
            this.$store.dispatch('setLoadingStatus', false)
            this.documents = this.$store.getters.getAllDocuments
          }
      )
    },
  },
  async created() {
    await this.retrieveFiles();
  }
}
</script>

<style scoped>

</style>