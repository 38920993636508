<template>
  <div >
    <app-header-no-back title="Dashboard"></app-header-no-back>
    <div v-if="userDashboardData.userCounts" >
      <div class="col-md-4 slide-left bg-white my-8 p-4 rounded-md border-1 border-primary2fade/5">
        <div class="flex items-center">
          <div class="h-12 w-12 bg-primary2variant-50 rounded-full flex justify-center items-center">
            <icon name="users" width="h-8" height="w-8" color="#0B5073"></icon>
          </div>
          <div class="mx-4">
            <h2 class="font-bold text-xl text-primary2">
              Total Users
            </h2>
            <span class="font-bold text-3xl">
            {{ userDashboardData.totalUsers }}
          </span>
          </div>
        </div>

      </div>
      <chart-component class="slide-left" title="Users By Position"
                       :categories="userDashboardData.userPositions"
                       :series="series" y-axis="Users"></chart-component>
    </div>
  </div>
</template>

<script>
import ChartComponent from "@/components/shared/ChartComponent.vue";
import Icon from "@/components/shared/Icon.vue";
import AppHeaderNoBack from "@/components/shared/AppHeaderNoBack.vue";

export default {
  name: "InstitutionDashboard",
  components: {AppHeaderNoBack, Icon, ChartComponent},
  data() {
    return {
      users: [],
      userDashboardData: {
        totalUsers: 0
      },
      series: [{
        name: 'Positions',
        data: []
      }]
    }
  },
  methods: {
    usersData() {
      let userPositions = []
      let userCounts = []
      for (const user of this.users) {
        this.userDashboardData.totalUsers += 1
        let position = userPositions.find((userPosition) => userPosition === user.position)
        if (!position) {
          userPositions.push(user.position)
          userCounts.push(1)
        }
        else {
          let positionIndex = userPositions.findIndex((userPosition) => userPosition === user.position)
          userCounts[positionIndex] += 1
        }
      }
      this.userDashboardData = {
        ...this.userDashboardData,
        userPositions,
        userCounts
      }
      this.series[0].data = userCounts
    }
  },
  mounted() {
    this.$store.dispatch('clearUsers')
    this.$store.dispatch('setLoadingStatus', true)
    this.$store.dispatch('fetchAllUsers').then(
        () => {
          this.$store.dispatch('setLoadingStatus', false)
          this.users = this.$store.getters.getAllUsers
          this.usersData()
        }
    )
  }
}
</script>

<style scoped>

</style>