<template>

  <div >
    <app-header-no-back title="Dashboard"></app-header-no-back>
    <div>
      <div class="col-md-4 slide-left bg-white my-8 p-4 rounded-md border-1 border-primary2fade/5">
        <div class="flex items-center">
          <div class="h-12 w-12 bg-primary2variant-50 rounded-full flex justify-center items-center">
            <icon name="document" width="h-8" height="w-8" color="#0B5073"></icon>
          </div>
          <div class="mx-4">
            <h2 class="font-bold text-xl text-primary2">
              Total Documents
            </h2>
            <span class="font-bold text-3xl">
            {{ documents.length }}
          </span>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import ChartComponent from "@/components/shared/ChartComponent.vue";
import AppHeaderNoBack from "@/components/shared/AppHeaderNoBack.vue";
import Icon from "@/components/shared/Icon.vue";

export default {
  name: "OperatorDashboard",
  components: {Icon, ChartComponent, AppHeaderNoBack},
  data() {
    return {
      documents: [],
    }
  },
  mounted() {
    this.$store.dispatch('clearDocuments')
    this.$store.dispatch('setLoadingStatus', true)
    this.$store.dispatch('fetchAllDocuments').then(
        () => {
          this.$store.dispatch('setLoadingStatus', false)
          this.documents = this.$store.getters.getAllDocuments
        }
    )
  }
}
</script>

<style scoped>

</style>