<template>
    <div class="h-52 bg-primary2 w-full p-8">
        <div class="bg-white rounded-lg p-4  flex mx-auto my-auto h-40 scale-up">
            <v-img src="@/assets/images/nembo.png" class="hover:cursor-pointer"/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'sidebarImage'
}
</script>
