<!-- @format -->

<template>

  <div class="w-full h-full relative bg-gradient">
    <v-img src="@/assets/images/nembo.png" class="absolute left-0 m-4"
           height="80px" width="100px"></v-img>
    <v-img src="@/assets/images/ega.png" class="absolute right-0 m-4"
           height="80px" width="120px"/>


    <div class="h-full w-full flex flex-col justify-center items-center">
      <h2 class="text-5xl scale-up text-center font-bold my-4"> Secure Document</h2>
      <div class="flex flex-col md:flex-row">
        <div class="p-4 w-60 mx-2 scale-up rounded-lg glass-card shadow-sm my-4 md:my-0">
          <h2 class="font-bold text-xl text-primary2">
            Document Storage
          </h2>
          <span class="text-sm text-justify">
            A temper proof system for storing Government documents utilizing Blockchain technology.
          </span>
        </div>
        <div class="p-4 w-60 mx-2 scale-up rounded-lg glass-card shadow-sm my-4 md:my-0">
          <h2 class="font-bold text-xl text-primary2">
            Share Document
          </h2>
          <span class="text-sm text-center">
            Securely share sensitive documents within your institution and across institutions.
          </span>
        </div>
<!--        <div class="p-4 w-60 mx-2 scale-up rounded-lg glass-card shadow-sm my-4 md:my-0">-->
<!--          <h2 class="font-bold text-xl text-primary2">-->
<!--            Verify Document-->
<!--          </h2>-->
<!--          <span class="text-sm text-justify">-->
<!--            Securely share sensitive documents within your institution and across institutions.-->
<!--          </span>-->
<!--        </div>-->
      </div>


      <div class="col-md-4 mt-4 h-20">
        <v-carousel v-model="model"
                    class="h-20"
                    :show-arrows="false"
                    height="200"
                    hide-delimiters :cycle="true" interval="3000">
          <v-carousel-item
          >
            <div class="flex flex-row cursor-pointer border-2 border-white/10 shadow-sm bg-white items-center rounded-full px-4 py-2"
            @click="navigateUrl('https://dumavpn.all.co.tz/')">
              <img src="../../assets/images/duma.png" alt="Duma Vpn" class="!h-8"/>
              <div class="grow px-4">
                Duma VPN
              </div>
            </div>
          </v-carousel-item>
          <v-carousel-item
          >
            <div class="flex flex-row cursor-pointer border-2 border-white/10 shadow-sm bg-white items-center rounded-full px-4 py-2"
            @click="navigateUrl('https://oxygen.co.tz/')">
              <img src="../../assets/images/oxygen.png" alt="Oxygen" class="!h-8"/>
              <div class="grow px-4">
                Oxygen
              </div>
            </div>
          </v-carousel-item>
          <v-carousel-item
          >
            <div class="flex flex-row cursor-pointer border-2 border-white/10 shadow-sm bg-white items-center rounded-full px-4 py-2"
            @click="navigateUrl('https://mikutano.gov.go.tz')">
              <img src="../../assets/images/emikutano.png" alt="eMikutano" class="!h-8"/>
              <div class="grow px-4">
                eMikutano
              </div>
            </div>
          </v-carousel-item>
          <v-carousel-item
          >
            <div class="flex flex-row cursor-pointer border-2 border-white/10 shadow-sm bg-white items-center rounded-full px-4 py-2"
            @click="navigateUrl('https://emrejesho.gov.go.tz/')">
              <img src="../../assets/images/emrejesho.png" alt="eMrejesho" class="!h-8"/>
              <div class="grow px-4">
                eMrejesho
              </div>
            </div>
          </v-carousel-item>
        </v-carousel>
      </div>

    </div>

    <div class="absolute right-0 top-0 flex justify-center items-center h-full">
      <div class="glass-card flex justify-center items-center hover:bg-primary2/10 cursor-pointer font-bold p-2 rounded-l-lg" @click="Login">
        <img src="../../assets/images/alphachain.png" alt="Alphachain" class="!h-8 mr-2"/> Connect
      </div>
    </div>

    <div class="absolute bottom-0 w-full bg-gray-100 text-center !text-primary2">
      <span class="text-sm ">Developed and Maintained by
        <a href="https://ega.go.tz" target="_blank" class="font-bold">eGA</a>
      </span>
    </div>

  </div>
</template>

<script>
import {ethers} from "ethers";
import {abi, contractAddress} from "../../../secDocConstants";
import {defineComponent} from "vue";

// Components

export default defineComponent({
  name: "LandingPortal",
  components: {},
  data() {
    return {
      dialog: false,
      orgs: {},
      path: "",
      model: 0
    };
  },

  methods: {
    async connectWallet() {
      if (typeof window.ethereum !== "undefined") {
        await window.ethereum.request({method: "eth_requestAccounts"});
        this.dialog = true;
      }
    },

    navigateUrl(route) {
      window.open(route)
    },

    async Login() {
      if (typeof window.ethereum !== undefined) {
        await window.ethereum.request({method: "eth_requestAccounts"});
        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = await provider.getSigner();
        const contract = new ethers.Contract(contractAddress, abi, signer);

        try {
          const user = await contract.operatorLogin(signer.getAddress());

          if (user) {
            console.log(user, 'user')
            this.path = user;
            this.$router.push(this.path)
          } else {
            alert("Unauthorised / unknown address");
          }
        } catch (err) {
          console.log(err, 'error');
        }
      }


    },
  },


});
</script>

<style scoped>
.b-left {
  background: white;
}

.b-right {
  background: #1badde;
  border-left: solid white;
  border-bottom: solid transparent;
}

.bg-image {
  background-image: url("../../assets/secdoc.png");
}

.text-big-blue {
  position: absolute;
  left: 7.08%;
  right: 49.17%;
  top: 12.22%;
  bottom: 73.11%;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 66px;
  /* or 103% */

  display: flex;
  align-items: center;

  color: #095073;
}

.text-big-blue2 {
  position: absolute;
  left: 7.08%;
  right: 49.17%;
  top: 22.22%;
  bottom: 65.11%;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 66px;
  /* or 103% */

  display: flex;
  align-items: center;

  color: #095073;
}

.form-position {
  position: absolute;
  left: 7.08%;
  right: 49.17%;
  top: 42.22%;
  bottom: 45.11%;
  width: 30%;
}
</style>
