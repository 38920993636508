<template>
  <div class="w-full">
    <highcharts :options="chartOptions"></highcharts>

  </div>
</template>

<script>
import {Chart} from "highcharts-vue";

export default {
  name: "ChartComponent",
  components: {
    highcharts: Chart
  },
  props: {
    chartType: {
      type: String,
      default: 'v-bar'
    }, // v-bar |
    title: String, // v-bar
    subtitle: String, // v-bar
    yAxis: String, // v-bar
    categories: [], // v-bar
    series: [], // v-bar
  },
  data() {
    return {
      singleColumnChartOptions: {
        chart: {
          type: 'column'
        },
        title: {
          text: this.title
        },
        subtitle: {
          text: this.subtitle
        },
        xAxis: {
          categories: this.categories,
          crosshair: true
        },
        yAxis: {
          min: 0,
          title: {
            text: this.yAxis
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        series: this.series
      },
      chartOptions: {}
    }
  },
  created() {
    if (this.chartType === 'v-bar') this.chartOptions = this.singleColumnChartOptions
  }
}
</script>

<style scoped>

</style>